import React from "react";
import { DataGrid, isArray } from "@material-ui/data-grid";
import { TableWrapper } from "./panelTable.styled";

import { EditButton } from "../../buttons/EditButton";
import { DeleteButton } from "../../buttons/DeleteButton";
import { ShowButton } from "../../buttons/ShowButton";

function PanelTable(props) {
  const columns = props.columns.map((column) => {
    return { field: column, headerName: column, flex: 1 };
  });
  const rows = props.rows;
  const editAccessIds = props.editAccessIds;

  function isShowEditButton(id) {
    if (!editAccessIds) return true;
    return editAccessIds.indexOf(id) > -1;
  }

  function getButtons() {
    return {
      field: "",
      headerName: "actions",
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        const api = params.api;
        const thisRow = {};

        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });

        const onClick = (callbackFunction) => {
          if (callbackFunction) callbackFunction(thisRow);
        };

        return (
          <>
            <ShowButton onClick={() => onClick(props.onShow)} />
            {isShowEditButton(thisRow.id) && (
              <EditButton onClick={() => onClick(props.onEdit)} />
            )}
            {props.isDeleteable && (
              <DeleteButton onClick={() => onClick(props.onDelete)} />
            )}
          </>
        );
      },
    };
  }

  columns.push(getButtons());
  return (
    <TableWrapper>
      <DataGrid
        {...props}
        rows={rows}
        columns={columns}
        pageSize={10}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        rowHeight={90}
      />
    </TableWrapper>
  );
}

export default PanelTable;
